<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  methods: {
    // 禁止打开控制台
    disableF12() {
      document.oncontextmenu = function () {
        return false;
      };
      console.log(Object.defineProperties(new Error(), {
        message: {
          get: function () {
            window.open('about:blank', '_self')
          },
        },
        toString: {
          value: function () {
            return new Error().message
          },
        }
      }))
    },
  },
  mounted() {
    if (process.env.NODE_ENV === "production") {
      this.disableF12();
    }
  },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
}
*::-webkit-scrollbar {
  width: 0;
  height: 0;
}
#app {
  min-height: 100vh;
  /* background: #000; */
}
</style>
