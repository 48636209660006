import Vue from "vue";
import VueRouter from "vue-router";
import { isH5 } from "../utils/platform";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Math",
    component: () =>
      isH5() ? import("../mobile/Math.vue") : import("../pages/Math.vue"),
  },
  {
    path: "/recruit",
    name: "Recruit",
    component: () =>
      isH5() ? import("../mobile/Recruit.vue") : import("../pages/Recruit.vue"),
  },
  // {
  //   path: "/downloadandroid",
  //   name: "downloadandroid",
  //   component: () => import("../mobile/downloadAndroid.vue"),
  // },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
