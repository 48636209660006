// 判断是否为H5
function isH5() {
  const reg = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  const ua = window.navigator.userAgent;
  if (reg.test(ua)) {
    return true;
  } else {
    return false;
  }
}

export { isH5 };
