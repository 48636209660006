import Vue from "vue";
import App from "./App.vue";
import { Dropdown, DropdownMenu, DropdownItem,Image } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import router from "./router";
const eventId = window.location?.href?.includes('downloadandroid') ? 'aiwebsite' : 'aiwebsites'
Vue.config.productionTip = false;
Vue.use(Dropdown).use(DropdownMenu).use(DropdownItem).use(Image);
import XesLoggerSDK from '@xes/xes_fe_log'
const SDKConfig = {
  baseURL: '', // 日志上传使用的接口
  appid: '1005451', // 日志上传的appid
  common: { eventid: eventId }, // 项目的唯一标识
  disableAgif: true, // 关闭展现日志上传(a.gif)
  clickMsg: { open: false }, // 关闭交互日志上传(b.gif)
  //【***注意***】trace打开后可能会造成跨域问题, 需要服务器做traceid header的跨域支持
  // 包含xueersi、xesv5、vdyoo、xiwang等域名的API网关下, 大部分默认支持该跨域规则，故推荐打开
  trace: { open: false }, //关闭traceid上传，推荐自行打开！以便跟踪用户操作路径
  vue: { errorHandler: true }, // 捕获Vue抛出的错误
}
Vue.use(XesLoggerSDK, SDKConfig)  //注册，建议最先use


new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
